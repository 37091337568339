import { createWebHistory, createRouter } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NoView from '@/views/NoView.vue'

const routes = [
  {
    path: '/:clientkey/:giftcardcode?',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/',
    name: '404',
    component: NoView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router