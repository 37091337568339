<template>
  <div>
	<h2>Check Gift Card Balance</h2>
	<div class="row margin-bottom small-gap fill" v-if="showInput">
		<div class="lookup">
			<input type="text" v-model="giftcardcode" size="15" />
		</div>
		<div class="button small" @click="navcheck">
			Check Balance
		</div>
	</div>
	<div class="row loader-row" v-if="loading">
		<loader-component/>
	</div>
	<div class="row">
		<div class="error" v-if="error">
			{{ error }}
		</div>
	</div>
	<div class="row">
		<div class="giftcard" v-if="giftcard">
			<div class="row gap">
				<img :src="logo"/>
				<div style="position: relative;">
					<span class="balance">Balance</span>
					<div class="bignumber"><small>$</small>{{ giftcard.balance }}</div>
				</div>
			</div>
			<div class="row fill gap">
				<div class="row2 item tall">
					<div class="right-align">
						<span>From</span><br/>
						<span>To</span><br/>
						<span>Original Amount</span>
					</div>
					<div>
						{{ giftcard.fromName }}<br/>
						{{ giftcard.fromName }}<br />
						${{ giftcard.amount }}
					</div>
				</div>
				<div class="item">
					<span>Message</span><br />
					<pre>{{ giftcard.message }}</pre>
				</div>
			</div>
		</div>
	</div>
	<div class="row" v-if="giftcard">
		<a class="button" :href="`${this.config.bookUrl}/${giftcard.clientLocationKey}`">
			View all experiences
		</a>
	</div>
</div>
</template>

<script>
import { reactive } from '@vue/reactivity'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  components: { LoaderComponent },
  inject: ["config"],
	name:"HomeView",
	data() {
		return {
			giftcard: false,
			error: false,
			loading: false,
			giftcardcode: "",
			showInput: false,
		}
	},
	mounted() {
		console.log(this.config)
		this.showInput = !this.$route.params.giftcardcode
		if (this.$route.params.giftcardcode) {
			this.giftcardcode = this.$route.params.giftcardcode
			this.check()
		} else {
			console.log("No giftcard")
		}
	}, 
	computed: {
		logo(){
			if (this.giftcard) {
				return `https://res.cloudinary.com/singenuity/c_fit,h_140,w_300/dev/${this.$route.params.clientkey}/${this.giftcard.clientLocationKey}/images/darklogo`
			} else {
				return ""
			}
		}
	}, methods: {
		navcheck() {
			this.$router.push(`/${this.$route.params.clientkey}/${this.giftcardcode}`)
			this.check()
		},	
		check() {
			console.log("Calling check")
			this.error = false
			if (this.giftcardcode.length < 3) { this.error = "This does not appear to be a valid gift card number!"; this.showInput = true; return; }
			this.loading = true
			
			const apiUrl = `${this.config.wrapperUrl}/GiftCard/GetGiftCard?clientkey=${this.$route.params.clientkey}&code=${this.giftcardcode}`
			this.$http.get(apiUrl).then(res => {
				this.loading = false
				if (res.data.giftCard) {
					this.giftcard = reactive(res.data.giftCard)
				} else {
					console.log("No data returned for gift card", this.giftcardcode, "at client", this.$route.params.clientkey,"(", apiUrl, ")")
					this.error = "This does not appear to be a valid gift card."
					this.showInput = true
				}
			}).catch(e => {
				this.loading = false
				console.log(e)
				this.error = "This does not appear to be a valid gift card."
				this.showInput = true
			})
		}
	}
}
</script>

<style>
input {
	font-family: "Avenir Next";
	font-weight: bolder;
	font-size: 22pt;
	border:0px;
	margin:5px 10px 0px;
	text-align: center;
	line-height: 50px;}
.button {
	border:0px;
	font-family: "Avenir Next";
	font-weight: bold;
	background-color: #09f;
	color:white;
	height:55px;
	line-height: 50px;
	border-radius: 10px;
	font-size: 18pt;
	padding:5px 10px 0px;
	border:2px solid #09f;
	text-align: center;
	cursor: pointer;
}
.margin-bottom {
	margin-bottom: 50px;
}
.small {
	width: 215px;
}
.lookup {
	border:2px solid #333;
	border-radius: 10px;
	background-color: white;
}
.row, .row2 {
	display: flex;
	justify-content: center;
}
.column {
	flex-direction: column;
}

.giftcard {
	display: flex;
	flex-direction: column;
	text-align: left;
	background-color:white;
	padding:30px 50px 20px 40px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
}
.giftcard span {
	font-weight: bolder;
	padding-right:6px;
}
.giftcard pre {
	background-color: #EEE;
	padding:20px;
}
.giftcard small {
	font-size:30pt;
	position: relative;
	top:-40px;
	left:-10px;
	font-weight: 400;
}
.balance {
	position: absolute;
	top:5px;
	left:20px;
}
.bignumber {
	font-size:80pt;
	font-weight: 200;
	letter-spacing: -20px;
}
.giftcard .row {
	align-items: flex-start;
}
.row.fill {
	justify-content: center;
}
.right-align {
	text-align: right;
}
.gap {
	gap: 60px;
}
.small-gap {
	gap: 10px;
}
.item {
	flex-grow: 1;
}
.tall {
	line-height: 30px;
}
a.button {
	text-decoration: none;
	margin-top:50px;
	padding:5px 60px 0px;
}
.loader-row {
	height:200px;
}
.loader {
	top:50%;
}
@media only screen and (max-width: 600px) {
	.gap {
		gap:20px;
	}
	.row {
		flex-direction: column;
		justify-content: center;
		align-items: stretch !important;
		padding:0px 20px;
	}
	.giftcard {
		/* gap: 20px; */
	}
	.giftcard img {
		width:200px;
	}
	.bignumber {
		font-size: 50pt;
		letter-spacing: -10px;
	}
	.balance {
		top: -5px;
	}
	.giftcard small {
		top: -15px;
		left:-5px;
	}
	.button, a.button {
		font-size: 16pt;
		padding: 5px 20px 0px;
	}
	.small-gap {
		gap:20px;
	}
	.fill {
		align-items: stretch;
	}
	.small {
		width: auto;
	}
}
</style>