import { createApp } from 'vue'
import App from './App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import router from '@/router'

if (process.env.VUE_APP_BUILD && process.env.VUE_APP_BUILD) {
    console.info(`Singenuity GiftCard - Build v${process.env.VUE_APP_BUILD}.${process.env.VUE_APP_BUILD}`);
}

console.log(window);

let configUrl = window.__CONFIG_URL;
console.log('configUrl: ', configUrl)

if (!configUrl) {
    configUrl = process.env.VUE_APP_DEV_CONFIG_URL;
}

fetch(`${configUrl}`)
    .then((config) => {
        console.log('config: ', config);
        return config.json();
    })
    .then((config) => {
        console.log('config: ', config)
        createApp(App)
            .use(VueAxios, axios)
            .use(router)
            .provide('config', config)
            .mount('#app');
    });